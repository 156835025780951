.sticky-nav {
    position: -webkit-sticky; /* For Safari */
    position: sticky;
    top: 20px; /* Adjust as needed */
    max-height: calc(100vh - 20px); /* Adjust based on your top value */
    overflow-y: auto;

    /* Custom scrollbar styles */
    scrollbar-width: thin;
    scrollbar-color: rgba(255, 255, 255, 0.5) transparent; /* Customize with your theme colors */

    &::-webkit-scrollbar {
        width: 8px;
    }

    &::-webkit-scrollbar-track {
        background: transparent;
    }

    &::-webkit-scrollbar-thumb {
        background-color: rgba(255, 255, 255, 0.5); /* Adjust color and opacity to match your design */
        border-radius: 4px;
        border: 2px solid transparent;
    }
}