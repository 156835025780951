.login {
    padding: $spacer * 1 $spacer;
    display: flex;
    align-items: center;

    @include media-breakpoint-down(md) {
        padding: $spacer;
    }

    & .login-content {
        max-width: rem(360px);
        margin: 0 auto;
        position: relative;
        flex: 1;
    }
}

.login-link {
    color: rgba(255, 255, 255, 0.5);
    text-decoration: none;
    transition: color 0.3s ease;
}

.login-link:hover {
    color: $theme !important; /* Change to fully opaque white on hover */
}

/* Container for the logo */
.login-logo {
    display: flex;
    justify-content: center; /* Center the logo horizontally */
    align-items: center; /* Center the logo vertically */
    width: 100%;
    //height: 100vh; /* Full viewport height */
    padding: $spacer; /* Add padding if needed */
    box-sizing: border-box;
	margin-top: 1rem;
}

/* Wrapper around the image */
.login-brand-img {
    display: flex;
    justify-content: center; /* Center the image within the container */
    align-items: center;
    width: 90%; /* Allow scaling */
    max-width: 450px; /* Restrict maximum size */
    margin: 0 auto; /* Center the wrapper */
}

/* Style for the <img> tag */
.login-brand-img img {
    width: 100%; /* Scale image proportionally to container */
    height: auto; /* Maintain aspect ratio */
    display: block; /* Prevent extra spacing around the image */
}

/* Media query for smaller screens */
@include media-breakpoint-down(md) {
    .login-logo {
        //padding: $spacer;
		margin-top: $spacer * 2;
    }

    .login-brand-img {
        max-width: 90%; /* Shrink the logo messurements for small devices */
        height: auto; /* Maintain aspect ratio */
    }
}

/* Media Query for Small Vertical Screens (Portrait Mode) */
@media (max-height: 700px) and (orientation: portrait) {
    .login-brand-img {
        width: 80%; /* Reduce size for smaller vertical screens */
        height: auto; /* Maintain aspect ratio */
    }
}