@import '_functions.scss';
@import '_variables.scss';
@import 'node_modules/bootstrap/scss/bootstrap.scss';
@import '_mixins.scss';

@import '_helper.scss';
@import '_reboot.scss';

@import '_app.scss';
@import '_layout.scss';
@import '_ui.scss';
@import '_plugins.scss';
@import '_pages.scss';
@import '_widgets.scss';
@import '_rtl.scss';
// @import url('https://fonts.googleapis.com/css2?family=Chakra+Petch:wght@300;500&display=swap');
@font-face {
    font-family: 'Hitmarker';
    src: url('./assets/fonts/HitmarkerText-Regular.woff2') format('woff2'),
         url('./assets/fonts/HitmarkerText-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Hitmarker';
    src: url('./assets/fonts/HitmarkerText-Bold.woff2') format('woff2'),
         url('./assets/fonts/HitmarkerText-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Hitmarker';
    src: url('./assets/fonts/HitmarkerText-Italic.woff2') format('woff2'),
         url('./assets/fonts/HitmarkerText-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
  }
  
  @font-face {
    font-family: 'Hitmarker';
    src: url('./assets/fonts/HitmarkerText-Medium.woff2') format('woff2'),
         url('./assets/fonts/HitmarkerText-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
  }
  
  body {
    font-family: 'Hitmarker', sans-serif;
  }
  
