body {
	& .react-select__control {
		border: 1px solid $border-color;
		background: none;
		border-radius: $border-radius;
		padding: 0.375rem 0.75rem;
		min-height: rem(35px);
		
		&:hover {
			border-color: $border-color;
		}
		&.react-select__control--is-focused {
			box-shadow: $input-btn-focus-box-shadow;
			border-color: $border-color;
		}
		& .react-select__value-container {
			padding: 0;
			
			& .react-select__placeholder {
				margin: 0;
				color: rgba($white, .5);
			}
			& .react-select__single-value {
				color: $white;
			}
			& .react-select__input-container {
				margin: 0;
				padding: 0;
				
				& .react-select__input {
					color: $white;
				}
			}
			&.react-select__value-container--is-multi {
				margin-bottom: rem(-5px);
				
				& .react-select__placeholder,
				& .react-select__input-container {
					margin-top: rem(-5px);
				}
				
				& .react-select__multi-value {
					margin: 0 rem(4px) rem(4px) 0;
					background: rgba($white, .25);
					
					& .react-select__multi-value__label {
						color: $white;
						font-size: rem(12px);
						padding: rem(2px);
						
						@if $enable-rtl {
							padding-right: rem(6px);
						} @else {
							padding-left: rem(6px);
						}
					}
					& .react-select__multi-value__remove {
						border-radius: 0;
						padding: 0 3px;
						
						&:hover {
							background: none;
							color: rgba($white, .5);
						}
					}
				}
			}
		}
		& .react-select__indicators {
			& .react-select__indicator {
				padding: 0;
				color: rgba($white, .5);
				
				&:hover {
					color: $white;
				}
			}
			& .react-select__indicator-separator {
				background: none;
				margin: 0;
			}
		}
	}
    & .react-select__menu {
        background: $dropdown-bg;
        border-radius: 0;
        border: 1px solid $dropdown-border-color;

        & .react-select__menu-list {
            // Custom scrollbar styles
            scrollbar-width: thin;
            scrollbar-color: rgba(255, 255, 255, 0.5) transparent; // Customize with your theme colors

            &::-webkit-scrollbar {
                width: 8px;
            }

            &::-webkit-scrollbar-track {
                background: transparent;
            }

            &::-webkit-scrollbar-thumb {
                background-color: rgba(255, 255, 255, 0.5); // Adjust color and opacity to match your design
                border-radius: 4px;
                border: 2px solid transparent;
            }

            // Existing focused and selected option styles
            & .react-select__option {
                &.react-select__option--is-focused {
                    background: $dropdown-link-hover-bg;
                }
                &.react-select__option--is-selected {
                    background: $dropdown-link-active-bg;
                    color: $dropdown-link-active-color;
                }
            }
        }
    }
}