.daterangepicker {
    z-index: 1060 !important;
}

.daterangepicker .applyBtn {
    border-color: var(--bs-theme) !important;
    /* Replace with your theme color */
    color: var(--bs-theme) !important;
}

.daterangepicker .applyBtn:hover {
    background-color: var(--bs-theme) !important;
    /* Replace with your theme color hover state */
    border-color: var(--bs-theme) !important;
    /* Replace with your theme color hover state */
    color: var(--bs-dark) !important;
}

.button-container {
    display: flex;
    align-items: center; // This aligns the buttons vertically in the middle.
    gap: 1px; // Adds space between the buttons.
}

// .daterangepicker {
//     width: 550px !important; /* Adjust width as needed */
//     font-size: 10px !important; /* Smaller text to fit the smaller form factor */
// }

// .daterangepicker .calendar-table {
//     width: 100% !important; /* Ensures the calendar table shrinks to fit the container */
// }

/* Optionally adjust the size of individual cells */
.daterangepicker .calendar-table td,
.daterangepicker .calendar-table th {
    padding: 6px !important;
    /* Smaller padding */
}