/* Set the map container to scale responsively */
/* Flex container to handle responsive resizing */
.map-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh; /* Adjust height as needed */
    overflow: hidden;
}

/* Full width and height for the map itself */
#world-map {
    width: 100%;
    height: 100%; /* Allow height to scale with the container */
    max-width: 100%;
    max-height: 100%;
}
// }


#world-map .jvectormap-container {
    // position: absolute;
    // top: 0;
    // left: 0;
    // bottom: 0;
    // right: 0;
    width: 100%;
    height: auto;
    max-width: 100%;
}
