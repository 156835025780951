/* React Tooltip Transition Time */

:root {
  --rt-transition-show-delay: 1.15s !important;
  --rt-transition-closing-delay: 2.15s !important;
}


/* Custom CSS transition */

.alert-transition-entering {
  opacity: 0;
  transition: opacity 6000ms ease-in-out;
}

.alert-transition-enter {
  // opacity: 100;
  // transition: opacity 1s ease-in-out;
}

.alert-transition-entered {
  opacity: 0;
  transition: opacity 6s ease-in-out;
}

.alert-transition-enter-active {
  // opacity: 0;
  // transition: opacity 0s ease-in-out;
}



.alert-transition-exiting {
  opacity: 0;
  //transition: opacity 1s ease-in-out;
}

.alert-transition-exit-active {
  opacity: 0;
  //transition: opacity 1s ease-in-out;
}

.alert-transition-exit {
  opacity: 0;
  //transition: opacity 0s ease-in-out;
}


.lobby-tooltip {
  z-index: 1000;
  --rt-transition-show-delay: 0.15s !important;
  --rt-transition-closing-delay: 0.15s !important;
  /* Ensure this is higher than the image's z-index */
}

// THE user cards and the button layouts
.card .btn-group {
  max-width: 100%;
  flex-wrap: wrap;
  overflow: hidden;
  // padding: 0.5rem; /* Optional padding for better alignment */
}

.card .btn-group .btn {
  // padding: 0.3rem 0.5rem; /* Adjust padding for compact fit */
  font-size: 0.875rem; /* Adjust font size if needed */
}