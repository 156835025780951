.center-of-map:before {
    position: absolute;
    content: "\271B";
    z-index: 990;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    font-size: 4rem;
  }